<template>
  <section id="header" class="section_first_block">
    <div class="filter_first_block"></div>
    <div class="contaire_first_block">
      <div class="container_right_main_block">
        <div class="box_left_navigation">
          <p class="font_12_400 color_white">logo</p>
          <div class="container_bottom_navigation">
            <div class="box_coordinates">
              <div class="inverted_stick"></div>
              <p class="font_12_400 color_white">
                5<br />5<br />.<br />0<br />9<br />3<br />0<br />2<br />5<br />&nbsp;<br />
                7<br />3<br />.<br />4<br />6<br />9<br />7<br />9<br />3
              </p>
              <div class="inverted_stick"></div>
            </div>
            <div class="box_social">
              <div class="social_href">
                <img src="@/assets/img/elements/tg.svg" />
              </div>
              <div class="social_href">
                <img src="@/assets/img/elements/mail.svg" />
              </div>
            </div>
          </div>
        </div>
        <div class="container_navigation">
          <div class="box_nav">
            <a
              href="#section_about_project"
              class="font_12_400 color_white hover_nav"
              >о проекте</a
            >
            <a
              href="#section_infrastructure"
              class="font_12_400 color_white hover_nav"
            >
              инфраструктура
            </a>
            <a
              href="#section_location"
              class="font_12_400 color_white hover_nav"
              >расположение</a
            >
            <a
              href="#section_questions"
              class="font_12_400 color_white hover_nav"
              >контакты</a
            >
          </div>
          <div class="box_mortgage">
            <p class="font_16_500 color_white">ИПОТЕКА от 6%</p>
          </div>
        </div>
      </div>
      <div class="container_right_main_block_v3">
        <div class="container_main_info">
          <a
            class="font_12_400 color_white text_align_right hover_nav"
            href="tel:+73990000000 "
            >+7 399 000 00 00
          </a>
          <div class="box_main_info">
            <div class="box_title_main_info">
              <p class="font_16_500 color_brown text_align_right">
                ОТ 8 МЛН. ₽
              </p>
              <p class="font_48_700 color_white text_align_right">
                КОТТЕДЖИ<br />
                В СЕЛО <br />«ПУШКИНО»
              </p>
              <p class="font_20_300 color_white text_align_right">
                2024 | 2026
              </p>
            </div>
            <p class="font_20_300 color_brown text_align_right">
              На выбор более 40<br />
              объектов недвижимости<br />
              от застройщика<br />
              с участками различной площади
            </p>
            <div class="box_button_absolut_v1">
              <a href="#section_layouts" class="button_v1_arrow">
                ПОДОБРАТЬ ПЛАНИРОВКУ
                <img src="@/assets/img/elements/arrow.svg" />
              </a>
            </div>
          </div>
        </div>
        <FeedbackFirst />
      </div>
      <div class="container_right_main_block_v2">
        <div>
          <FeedbackFirst />
          <div class="container_nav_mobail">
            <div class="box_nav">
              <a
                href="#section_about_project_v2"
                class="font_12_400 color_white hover_nav"
                >о проекте</a
              >
              <a
                href="#section_infrastructure"
                class="font_12_400 color_white hover_nav"
              >
                инфраструктура
              </a>
              <a
                href="#section_location"
                class="font_12_400 color_white hover_nav"
                >расположение</a
              >
              <a
                href="#section_questions"
                class="font_12_400 color_white hover_nav"
                >контакты</a
              >
            </div>
            <a
              class="font_12_400 color_white text_align_right hover_nav"
              href="tel:+73990000000 "
              >+7 399 000 00 00
            </a>
          </div>
        </div>
        <div class="container_first_mobil">
          <div class="container_main_info">
            <div class="box_main_info">
              <div class="box_title_main_info">
                <p class="font_16_500 color_brown text_align_right">
                  ОТ 8 МЛН. ₽
                </p>
                <p class="font_48_700 color_white text_align_right">
                  КОТТЕДЖИ<br />
                  В СЕЛО <br />«ПУШКИНО»
                </p>
                <p class="font_20_300 color_white text_align_right">
                  2024 | 2026
                </p>
              </div>
              <div class="box_button_absolut_v1">
                <a
                  href="#section_layouts"
                  class="button_v1_arrow text_align_center"
                >
                  ПОДОБРАТЬ <br />
                  ПЛАНИРОВКУ
                  <img src="@/assets/img/elements/arrow.svg" />
                </a>
              </div>
              <p class="font_20_300 color_brown text_align_right">
                На выбор более 40<br />
                объектов недвижимости<br />
                от застройщика<br />
                с участками различной площади
              </p>
              <div class="box_mortgage">
                <p class="font_16_500 color_white">ИПОТЕКА от 6%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <FeedbackSecond />
  <section id="section_infrastructure" class="section_infrastructure">
    <div class="box_text_infrastructure">
      <p class="font_48_700 color_white text_align_right">ИНФРАСТРУКТУРА</p>
      <p class="font_20_300 color_white text_align_right text_infrastructure">
        Удачное расположение квартала позволяет быстро добраться до<br />
        автобусной остановки, детского досугового центра, кафе, детского<br />
        сада и школы.
      </p>
      <p
        class="font_20_300 color_white text_align_right text_infrastructure_v2"
      >
        Удачное расположение квартала позволяет<br />
        быстро добраться доавтобусной остановки,<br />
        детского досугового центра, кафе, детского<br />
        сада и школы.
      </p>
    </div>
    <div class="container_infrastructure">
      <div class="box_infrastructure">
        <img
          class="img_infrastructure"
          src="@/assets/img/background/transport.png"
        />
        <div class="text_img_infrastructure">
          <p class="font_16_700 color_white text_align_right">ТРАНСПОРТ</p>
        </div>
        <div class="box_check_mark">
          <img class="check_mark" src="@/assets/img/elements/check_mark.svg" />
        </div>
      </div>
      <div class="box_infrastructure">
        <img
          class="img_infrastructure"
          src="@/assets/img/background/shop.svg"
        />
        <div class="text_img_infrastructure bg_white">
          <p class="font_16_700 color_black text_align_right">
            МАГАЗИНЫ И СУПЕРМАРКЕТЫ
          </p>
        </div>
        <div class="box_check_mark">
          <img class="check_mark" src="@/assets/img/elements/check_mark.svg" />
        </div>
      </div>
      <div class="box_infrastructure">
        <img
          class="img_infrastructure"
          src="@/assets/img/background/education.svg"
        />
        <div class="text_img_infrastructure bg_white">
          <p class="font_16_700 color_black text_align_right">
            ОБРАЗОВАТЕЛЬНЫЕ УЧРЕЖДЕНИЯ
          </p>
        </div>
        <div class="box_check_mark">
          <img class="check_mark" src="@/assets/img/elements/check_mark.svg" />
        </div>
      </div>
      <div class="box_infrastructure">
        <img
          class="img_infrastructure"
          src="@/assets/img/background/medicine.svg"
        />
        <div class="text_img_infrastructure">
          <p class="font_16_700 color_white text_align_right">
            АПТЕКИ И БОЛЬНИЦЫ
          </p>
        </div>
        <div class="box_check_mark">
          <img class="check_mark" src="@/assets/img/elements/check_mark.svg" />
        </div>
      </div>
      <div class="box_infrastructure">
        <img
          class="img_infrastructure"
          src="@/assets/img/background/leisure.svg"
        />
        <div class="text_img_infrastructure bg_white">
          <p class="font_16_700 color_black text_align_right">
            МЕСТА ДЛЯ ДОСУГА И ОТДЫХА
          </p>
        </div>
        <div class="box_check_mark">
          <img class="check_mark" src="@/assets/img/elements/check_mark.svg" />
        </div>
      </div>
    </div>
    <div class="container_infrastructure_v2">
      <div class="container_left_infrastructure_v2">
        <div class="box_infrastructure">
          <img
            class="img_infrastructure"
            src="@/assets/img/background/transport.png"
          />
          <div class="text_img_infrastructure">
            <p class="font_16_700 color_white text_align_right">ТРАНСПОРТ</p>
          </div>
          <div class="box_check_mark">
            <img
              class="check_mark"
              src="@/assets/img/elements/check_mark.svg"
            />
          </div>
        </div>
        <div class="box_infrastructure">
          <img
            class="img_infrastructure"
            src="@/assets/img/background/education.svg"
          />
          <div class="text_img_infrastructure bg_white">
            <p class="font_16_700 color_black text_align_right">
              ОБРАЗОВАТЕЛЬНЫЕ УЧРЕЖДЕНИЯ
            </p>
          </div>
          <div class="box_check_mark">
            <img
              class="check_mark"
              src="@/assets/img/elements/check_mark.svg"
            />
          </div>
        </div>
        <div class="box_infrastructure">
          <img
            class="img_infrastructure"
            src="@/assets/img/background/medicine.svg"
          />
          <div class="text_img_infrastructure">
            <p class="font_16_700 color_white text_align_right">
              АПТЕКИ И БОЛЬНИЦЫ
            </p>
          </div>
          <div class="box_check_mark">
            <img
              class="check_mark"
              src="@/assets/img/elements/check_mark.svg"
            />
          </div>
        </div>
      </div>
      <div class="container_left_infrastructure_v3">
        <div class="box_infrastructure">
          <img
            class="img_infrastructure"
            src="@/assets/img/background/shop.svg"
          />
          <div class="text_img_infrastructure bg_white">
            <p class="font_16_700 color_black text_align_right">
              МАГАЗИНЫ И СУПЕРМАРКЕТЫ
            </p>
          </div>
          <div class="box_check_mark">
            <img
              class="check_mark"
              src="@/assets/img/elements/check_mark.svg"
            />
          </div>
        </div>

        <div class="box_infrastructure">
          <img
            class="img_infrastructure"
            src="@/assets/img/background/leisure.svg"
          />
          <div class="text_img_infrastructure bg_white">
            <p class="font_16_700 color_black text_align_right">
              МЕСТА ДЛЯ ДОСУГА И ОТДЫХА
            </p>
          </div>
          <div class="box_check_mark">
            <img
              class="check_mark"
              src="@/assets/img/elements/check_mark.svg"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section_communications">
    <div class="box_text_communications_margin">
      <p class="font_48_700 color_green text_align_right">КОММУНИКАЦИИ</p>
    </div>
    <div class="container_communications">
      <div class="box_communications">
        <img
          class="img_communications_light"
          src="@/assets/img/elements/light.svg"
        />
        <p class="font_16_400 color_green text_align_right">
          Электричество и система умного освещения
        </p>
      </div>
      <div class="box_communications">
        <img
          class="img_communications_gas-pipe"
          src="@/assets/img/elements/gas-pipe.svg"
        />
        <p class="font_16_400 color_green text_align_right">
          Электричество и система умного освещения
        </p>
      </div>
      <div class="box_communications">
        <img
          class="img_communications_water"
          src="@/assets/img/elements/water.svg"
        />
        <p class="font_16_400 color_green text_align_right">
          Электричество и система умного освещения
        </p>
      </div>
      <div class="box_communications">
        <img
          class="img_communications_sewerage"
          src="@/assets/img/elements/sewerage.svg"
        />
        <p class="font_16_400 color_green text_align_right">
          Электричество и система умного освещения
        </p>
      </div>
      <div class="box_communications">
        <img
          class="img_communications_speed"
          src="@/assets/img/elements/speed.svg"
        />
        <p class="font_16_400 color_green text_align_right">
          Электричество и система умного освещения
        </p>
      </div>
    </div>
  </section>
  <section class="section_environmental">
    <div class="container_environmental">
      <p class="font_32_700 color_green text_align_right">
        ЭКОЛОГИЧЕСКИ ЧИСТЫЙ ВОЗДУХ И ВОДА — БЕСЦЕННО,
        <span class="color_black">КАК И ВАШЕ ЗДОРОВЬЕ</span>
      </p>
      <div class="box_text_environmental">
        <p class="font_16_400 color_green text_align_right">
          Выбрав посёлок Пушкино, вы дарите вашей семье возможность погрузиться
          в объятия природы и заботы, проверенной годами.
        </p>
        <p class="font_16_400 color_green text_align_right">
          В нашем уголке вы вдохнете полной грудью чистейший воздух, насладитесь
          прекрасными пейзажами и окунетесь в атмосферу экологического
          благополучия. Здесь каждый день – это возможность жить в гармонии с
          природой, чувствуя связь с землей и ее щедрыми дарами.
        </p>
      </div>
    </div>
  </section>
  <SliderLayouts />
  <MobilLayouts />
  <section class="section_finishing">
    <div class="container_brown">
      <p class="font_48_700 color_white text_align_right">ВАРИАНТЫ ОТДЕЛКИ</p>
      <p class="font_20_400 color_white text_align_right">
        Выбирайте самостоятельно, как будет выглядеть ваш идеальный дом
      </p>
    </div>
    <div class="container_finishing_absolut">
      <div class="box_finishing_absolut">
        <img
          src="@/assets/img/background/basic.png"
          class="img_finishing_absolut"
        />
        <div class="box_text_finishing_absolut">
          <p class="font_28_700 color_white">Базовая</p>
        </div>
      </div>
      <div class="box_finishing_absolut">
        <img
          src="@/assets/img/background/semi-finished.png"
          class="img_finishing_absolut"
        />
        <div class="box_text_finishing_absolut">
          <p class="font_28_700 color_white">Получистовая</p>
        </div>
      </div>
      <div class="box_finishing_absolut">
        <img
          src="@/assets/img/background/finishing.png"
          class="img_finishing_absolut"
        />
        <div class="box_text_finishing_absolut">
          <p class="font_28_700 color_white">Чистовая</p>
        </div>
      </div>
    </div>
  </section>
  <section id="section_location" class="section_location">
    <div class="container_brown_location">
      <p class="font_32_700 color_white text_align_right">
        УДОБНОЕ РАСПОЛОЖЕНИЕ
      </p>
      <p class="font_20_400 color_white text_align_right">19 минут до города</p>
    </div>
    <img class="map_img" src="@/assets/img/background/map.png" />
  </section>
  <section class="section_map">
    <iframe
      src="https://yandex.ru/map-widget/v1/?um=constructor%3Aa382ef20e7c6ff51900a186557dd9d34ab7e2648f9c4737fdaa30cd3a936491b&amp;source=constructor"
      width="100%"
      height="100%"
      frameborder="0"
    ></iframe>
    <div class="box_brown_map">
      <div class="box_text_brown_map">
        <p class="font_32_700 color_white text_align_right">КАК НАС НАЙТИ</p>
        <p class="font_20_400 color_white text_align_right">
          “LOGO” и офис продаж
        </p>
      </div>
      <p class="font_12_400 color_white text_align_right">
        Время работы:<br />
        Ежедневно 09:00 - 19:00
      </p>
      <p class="font_12_400 color_white text_align_right">
        г. Омск, пр. Мира 12
      </p>
    </div>
  </section>
  <section id="section_questions" class="section_questions">
    <p class="font_48_700 color_white text_align_right">Остались вопросы?</p>
    <p class="font_20_400 color_white text_align_right">
      Отправьте заявку на обратный звонок<br />и мы расскажем о проекте.
    </p>
    <div class="box_input_questions">
      <div class="box_input_feedback">
        <div v-if="nameError" class="error">{{ nameError }}</div>
        <input
          class="input_feedback"
          placeholder="Ваше имя"
          type="text"
          v-model="name"
        />
      </div>
      <div class="box_input_feedback">
        <div v-if="phoneError" class="error">{{ phoneError }}</div>
        <input
          class="input_feedback"
          placeholder="+ 7 (999) 999-99-99"
          type="tel"
          v-model="phone"
        />
      </div>

      <button
        class="big_button_v1"
        @click="handleSubmit"
        :class="{ button_green: isValid }"
      >
        ОТПРАВИТЬ
        <span ref="button_true" class="button_true font_16_400 color_green"
          >Спасибо! Данные успешно отправлены.</span
        >
      </button>
    </div>
  </section>
  <FooterElement />
</template>

<script>
import FeedbackFirst from "@/components/FeedbackFirst.vue";
import FeedbackSecond from "@/components/FeedbackSecond.vue";
import SliderLayouts from "@/components/SliderLayouts.vue";
import MobilLayouts from "@/components/MobilLayouts.vue";
import FooterElement from "@/components/FooterElement.vue";

export default {
  name: "App",
  components: {
    FeedbackFirst,
    FeedbackSecond,
    SliderLayouts,
    MobilLayouts,
    FooterElement,
  },
  data() {
    return {
      name: "",
      phone: "",
      nameError: null,
      phoneError: null,
      isValid: false,
    };
  },
  methods: {
    handleSubmit() {
      this.validateForm();
      if (!this.nameError && !this.phoneError) {
        this.isValid = true; // Устанавливаем флаг в true при успешной валидации
        console.log("Имя:", this.name);
        console.log("Телефон:", this.phone);
        this.$refs.button_true.classList.toggle("active");
      } else {
        this.isValid = false; // Сбрасываем флаг при неудачной валидации
      }
    },
    validateForm() {
      this.nameError = this.name.length < 2 ? "*не менее двух символов" : null;
      this.phoneError = !this.validatePhone(this.phone)
        ? "*некорректно введён номер"
        : null;
    },
    validatePhone(phone) {
      // Убираем пробелы, скобки и дефисы, чтобы упростить проверку
      phone = phone.replace(/\s|\(|\)|/g, ""); // Удалили обратную косую черту перед дефисом

      // Проверка на валидность российского номера (11 цифр, начинающихся с 7, 8 или 9)
      const regex = /^((8|\+7|9)\d{10})$/;
      return regex.test(phone);
    },
  },
};
</script>
<style>
@import "@/assets/css/reset.css";
@import "@/assets/css/init.css";
</style>
