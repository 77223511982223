<template>
  <div class="box_button_order">
    <div ref="section_button_connection" class="section_button_connection">
      <div class="box_text_order">
        <p
          @click="toggleModal"
          ref="font_12_400"
          class="font_12_400 color_white text_align_center hover_nav"
        >
          ОБРАТНАЯ<br />
          СВЯЗЬ
        </p>
        <img
          @click="closeModal"
          ref="img_cross"
          class="img_cross"
          src="@/assets/img/elements/cross.svg"
        />
        <div ref="box_margin_num_v2" class="box_margin_num_v2">
          <a
            class="font_12_400 color_white text_align_right hover_nav"
            href="tel:+73990000000 "
            >+7 399 000 00 00
          </a>
        </div>
      </div>
      <div ref="container_modal_feedback" class="container_modal_feedback">
        <p class="pseudo_text text_align_right">
          ОБРАТНАЯ<br />
          СВЯЗЬ
        </p>
        <div class="box_main_info_modal">
          <div class="box_margin_num">
            <a
              class="font_12_400 color_dark_brown text_align_right hover_nav"
              href="tel:+73990000000 "
              >+7 399 000 00 00
            </a>
          </div>

          <p class="font_48_700 color_dark_brown text_align_right">
            Заказать<br />
            обратный звонок
          </p>
          <p class="font_16_400 color_brown text_align_right">
            Заполните форму и наш менеджер свяжется с вами<br />
            в ближайшее время
          </p>

          <div class="box_input_feedback">
            <div v-if="nameError" class="error">{{ nameError }}</div>
            <input
              class="input_feedback"
              placeholder="Ваше имя"
              type="text"
              v-model="name"
            />
          </div>
          <div class="box_input_feedback">
            <div v-if="phoneError" class="error">{{ phoneError }}</div>
            <input
              class="input_feedback"
              placeholder="+ 7 (999) 999-99-99"
              type="tel"
              v-model="phone"
            />
          </div>

          <button
            class="big_button_v1"
            @click="handleSubmit"
            :class="{ button_green: isValid }"
          >
            ОТПРАВИТЬ
            <span ref="button_true" class="button_true font_16_400 color_green"
              >Спасибо! Данные успешно отправлены.</span
            >
          </button>
        </div>

        <p class="font_12_400 color_brown text_align_right">
          Нажимая на кнопку, вы даете согласие на обработку персональных<br />
          данных и соглашаетесь c
          <a class="text_underline">политикой конфиденциальности</a>
        </p>
      </div>
      <div ref="container_modal_feedback" class="container_modal_feedback_v2">
        <p class="pseudo_text text_align_right">
          ОБРАТНАЯ<br />
          СВЯЗЬ
        </p>
        <div class="box_main_info_modal">
          <p class="font_48_700 color_dark_brown text_align_right">
            Заказать<br />
            обратный<br />
            звонок
          </p>
          <p class="font_16_400 color_brown text_align_right">
            Заполните форму и наш менеджер<br />
            свяжется с вами в ближайшее<br />
            время
          </p>

          <div class="box_input_feedback">
            <div v-if="nameError" class="error">{{ nameError }}</div>
            <input
              class="input_feedback"
              placeholder="Ваше имя"
              type="text"
              v-model="name"
            />
          </div>
          <div class="box_input_feedback">
            <div v-if="phoneError" class="error">{{ phoneError }}</div>
            <input
              class="input_feedback"
              placeholder="+ 7 (999) 999-99-99"
              type="tel"
              v-model="phone"
            />
          </div>

          <button
            class="big_button_v1"
            @click="handleSubmit"
            :class="{ button_green: isValid }"
          >
            ОТПРАВИТЬ
            <span ref="button_true_v2" class="button_true font_16_400 color_green"
              >Спасибо! Данные успешно отправлены.</span
            >
          </button>
        </div>

        <p class="font_12_400 color_brown text_align_right">
          Нажимая на кнопку, вы даете согласие на обработку персональных<br />
          данных и соглашаетесь c
          <a class="text_underline">политикой конфиденциальности</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      name: "",
      phone: "",
      nameError: null,
      phoneError: null,
      isValid: false,
    };
  },
  methods: {
    toggleModal() {
      this.$refs.box_margin_num_v2.classList.toggle("active");
      this.$refs.font_12_400.classList.toggle("active");
      this.$refs.img_cross.classList.toggle("active");
      this.$refs.section_button_connection.classList.toggle("active");
      document.body.classList.toggle("no-scroll");
    },
    closeModal() {
      this.$refs.box_margin_num_v2.classList.toggle("active");
      this.$refs.font_12_400.classList.toggle("active");
      this.$refs.img_cross.classList.toggle("active");
      this.$refs.section_button_connection.classList.toggle("active");
      document.body.classList.remove("no-scroll");
    },
    handleSubmit() {
      this.validateForm();
      if (!this.nameError && !this.phoneError) {
        this.isValid = true; // Устанавливаем флаг в true при успешной валидации
        console.log("Имя:", this.name);
        console.log("Телефон:", this.phone);
        this.$refs.button_true.classList.toggle("active");
        this.$refs.button_true_v2.classList.toggle("active");
      } else {
        this.isValid = false; // Сбрасываем флаг при неудачной валидации
      }
    },
    validateForm() {
      this.nameError = this.name.length < 2 ? "*не менее двух символов" : null;
      this.phoneError = !this.validatePhone(this.phone)
        ? "*некорректно введён номер"
        : null;
    },
    validatePhone(phone) {
      // Убираем пробелы, скобки и дефисы, чтобы упростить проверку
      phone = phone.replace(/\s|\(|\)|/g, ""); // Удалили обратную косую черту перед дефисом

      // Проверка на валидность российского номера (11 цифр, начинающихся с 7, 8 или 9)
      const regex = /^((8|\+7|9)\d{10})$/;
      return regex.test(phone);
    },
  },
};
</script>
