<template>
  <section id="section_layouts" class="section_layouts">
    <div class="block_green"></div>
    <div class="box_text_communications_margin">
      <p class="font_48_700 color_green text_align_right">ПЛАНИРОВКИ</p>
    </div>
    <div class="container_layouts">
      <div class="box_text_layouts">
        <p class="font_20_300 color_green text_align_right">
          Коттеджи представлены выборочно.<br />
          Свяжитесь с нами, чтобы увидеть все варианты!
        </p>
        <div class="box_button_layouts">
          <div class="button_left" @click="prevSlide"></div>
          <div class="button_right" @click="nextSlide"></div>
          <p class="font_28_300 color_white">{{ slideNumber }}</p>
        </div>
      </div>
      <div class="container_slider_layouts">
        <div class="slider-container" ref="sliderContainer">
          <div class="slider" id="slider">
            <a
              href="#section_layouts"
              v-for="(layout, index) in layouts"
              :key="index"
              class="box_layouts"
              @click="() => toggleAllModals(index)"
            >
              <img class="img_layouts" :src="layout.image" />
              <!-- 
              src="@/assets/img/background/1_house_option.png"
            :src="layout.image" -->
              <div class="text_layouts">
                <p
                  class="font_28_700 text_align_right color_green"
                  :class="{ 'text-on-green': layout.isTextOnGreen }"
                >
                  {{ layout.title }}<br />
                  {{ layout.subtitle }}
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div ref="container_modal" class="container_modal">
      <div class="block_grey"></div>
      <div class="box_button_layouts_modal">
        <div class="button_left_modal" @click="prevSlideModal"></div>
        <div class="button_right_modal" @click="nextSlideModal"></div>
        <p class="font_28_300">{{ slideNumberModal }}</p>
        <div @click="closeModal" class="button_cross_modal"></div>
      </div>
      <div class="container_slider_modal" ref="sliderContainerModal">
        <div
          v-for="(layout, index) in layouts"
          :key="index"
          class="box_modal_layouts"
        >
          <div ref="container_layout_drawing" class="container_layout_drawing">
            <div class="box_modal_layouts_v2">
              <div class="box_text_layouts">
                <p class="font_28_700 text_align_right color_green">
                  {{ layout.title }}
                  {{ layout.subtitle }}
                </p>
              </div>
              <div class="box_layout_drawing">
                <img class="big_project_img" :src="layout.project_big_1" />
                <img class="big_project_img" :src="layout.project_big_1" />

                <div
                  class="buttom_open_modal_v3"
                  @click="() => closeModalDrawing(index)"
                >
                  <img
                    src="@/assets/img/modal/button_left_modal.svg"
                    class="img_buttom_close_modal"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="box_text_layouts">
            <p class="font_28_700 text_align_right color_green">
              {{ layout.title }}
              {{ layout.subtitle }}
            </p>
          </div>
          <div class="box_conten_modal_layouts">
            <div class="block_conten_modal_layouts">
              <div
                class="buttom_open_modal_v2"
                @click="() => toggleModalDrawing(index)"
              >
                <img
                  src="@/assets/img/modal/buttom_open_modal.svg"
                  class="img_buttom_open_modal"
                />
              </div>
              <img class="small_project_img" :src="layout.project_small" />
              <div class="box_characteristics_text_modal_layouts">
                <div class="box_characteristics_modal_layouts">
                  <div class="characteristics_modal_layouts">
                    <img
                      class="bedrooms"
                      src="@/assets/img/modal/bedrooms.svg"
                    />
                    <p class="font_16_500_no color_green">
                      {{ layout.bedrooms }} спальни
                    </p>
                  </div>
                  <div class="characteristics_modal_layouts">
                    <img
                      class="bedrooms"
                      src="@/assets/img/modal/bathrooms.svg"
                    />
                    <p class="font_16_500_no color_green">
                      {{ layout.bathrooms }} санузла
                    </p>
                  </div>
                  <div class="characteristics_modal_layouts">
                    <img
                      class="bedrooms"
                      src="@/assets/img/modal/terraces.svg"
                    />
                    <p class="font_16_500_no color_green">
                      {{ layout.terraces }} терраса
                    </p>
                  </div>
                </div>
                <p class="font_20_700">{{ layout.characteristics }}</p>
                <p class="font_16_500_no color_green">
                  {{ layout.characteristics_2 }}
                </p>
                <p class="font_16_500_no color_green">
                  {{ layout.characteristics_3 }}
                </p>
              </div>
            </div>
            <img class="img_layouts_modal" :src="layout.image" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref, onMounted, computed } from "vue";

export default {
  methods: {
    toggleModalDrawing(index) {
      // Доступ к нужному элементу в массиве
      this.$refs.container_layout_drawing[index].classList.toggle("active");
    },
    closeModalDrawing(index) {
      this.$refs.container_layout_drawing[index].classList.toggle("active");
    },
  },
  setup() {
    const layouts = ref([
      {
        image: "/img/1_house_option.3224d407.png",
        title: "Коттедж",
        subtitle: "“Название1”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/2_house_option.ba06ffa2.png",
        title: "Коттедж",
        subtitle: "“Название2”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/3_house_option.e9f04c6a.png",
        title: "Коттедж",
        subtitle: "“Название3”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/1_house_option.3224d407.png",
        title: "Коттедж",
        subtitle: "“Название1”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/2_house_option.ba06ffa2.png",
        title: "Коттедж",
        subtitle: "“Название2”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/3_house_option.e9f04c6a.png",
        title: "Коттедж",
        subtitle: "“Название3”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/1_house_option.3224d407.png",
        title: "Коттедж",
        subtitle: "“Название1”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/2_house_option.ba06ffa2.png",
        title: "Коттедж",
        subtitle: "“Название2”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/3_house_option.e9f04c6a.png",
        title: "Коттедж",
        subtitle: "“Название3”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
    ]);

    const sliderContainer = ref(null); // Ссылка на контейнер слайдера
    const sliderContainerModal = ref(null); // Ссылка на контейнер слайдера
    const currentSlide = ref(0); // Текущий слайд (начинаем с 0)
    const currentSlideModal = ref(0); // Текущий слайд (начинаем с 0)
    const currentSlideIndex = ref(3); // Текущий индекс слайда (начинаем с 3)
    const currentSlideIndexModal = ref(1); // Текущий индекс слайда (начинаем с 3)
    const maxSlideOffset = ref(layouts.value.length); // Максимальное количество сдвигов
    const isTextOnGreen = ref(false);
    const touchStartX = ref(0);
    const isDragging = ref(false);
    let activeLayoutIndex = 1;

    const updateTextOnGreen = (index) => {
      // Получите элемент с текстом *внутри текущего слайда*
      const textElement = document.querySelector(
        `.box_layouts:nth-child(${index + 1}) .text_layouts`
      );

      const greenElement = document.querySelector(".block_green");

      const isIntersecting =
        textElement.getBoundingClientRect().x <=
          greenElement.getBoundingClientRect().right &&
        textElement.getBoundingClientRect().right >=
          greenElement.getBoundingClientRect().x &&
        textElement.getBoundingClientRect().y <=
          greenElement.getBoundingClientRect().bottom &&
        textElement.getBoundingClientRect().bottom >=
          greenElement.getBoundingClientRect().y;

      // Создайте новую переменную для текущего элемента
      layouts.value[index].isTextOnGreen = isIntersecting;
    };

    const updateSlide = () => {
      updateSlider(); // Обновите слайдер

      // Задержка для синхронизации с анимацией перехода (0.5s)
      setTimeout(() => {
        layouts.value.forEach((layout, index) => {
          updateTextOnGreen(index); // Обновите состояние для каждого элемента
        });
      }, 400);
    };
    const toggleAllModals = (index) => {
      // 1. Рассчитываем разницу между текущим индексом и новым
      const indexDifference = index + 1 - currentSlideIndexModal.value;

      // 2. Определяем направление пролистывания
      const direction = indexDifference > 0 ? 1 : -1;

      currentSlideIndexModal.value = index + 1;
      activeLayoutIndex = currentSlideIndexModal.value;
      updateActiveLayout();
      // 3. Вызываем updateSliderModal() нужное количество раз
      for (let i = 0; i < Math.abs(indexDifference); i++) {
        currentSlideModal.value += direction; // Изменяем currentSlideModal
        updateSliderModal();
      }

      // 4. Обновляем currentSlideIndexModal в самом конце
      currentSlideIndexModal.value = index + 1;

      // 5. Открываем модальные окна
      const modals = document.querySelectorAll(".container_modal");
      modals.forEach((modal) => {
        modal.classList.add("active");
      });
      document.body.classList.toggle("no-scroll");
    };

    const closeModal = () => {
      const modals = document.querySelectorAll(".container_modal");

      modals.forEach((modal) => {
        modal.classList.remove("active");
      });

      document.body.classList.remove("no-scroll");
    };
    const prevSlide = () => {
      if (currentSlideIndex.value > 3) {
        currentSlideIndex.value--;
        currentSlide.value--;
        updateSlide();
      }
    };
    const nextSlide = () => {
      if (currentSlideIndex.value < maxSlideOffset.value) {
        currentSlideIndex.value++;
        currentSlide.value++;
        updateSlide();
      }
    };
    const prevSlideModal = () => {
      if (currentSlideIndexModal.value > 1) {
        currentSlideIndexModal.value--;
        activeLayoutIndex--;

        currentSlideModal.value--;
        updateSliderModal();
        updateActiveLayout();
      }
    };
    const nextSlideModal = () => {
      if (currentSlideIndexModal.value < maxSlideOffset.value) {
        currentSlideIndexModal.value++;
        activeLayoutIndex++;

        currentSlideModal.value++;
        updateSliderModal();
        updateActiveLayout();
      }
    };

    const updateSliderModal = () => {
      if (sliderContainerModal.value) {
        sliderContainerModal.value.style.transform = `translateX(${
          currentSlideModal.value * -72
        }rem)`;
      }
    };
    const removeActiveClass = (layoutElements) => {
      layoutElements.forEach((element) => {
        element.classList.remove("active");
      });
    };
    const updateActiveLayout = () => {
      // Получаем все элементы box_modal_layouts
      const layoutElements = document.querySelectorAll(".box_modal_layouts");

      // Если у нас есть элементы
      if (layoutElements.length > 0) {
        // Удаляем класс active
        removeActiveClass(layoutElements);
        // Добавляем класс active к элементу с нужным индексом
        const newIndex = activeLayoutIndex - 1; // Используем activeLayoutIndex
        if (newIndex >= 0 && newIndex < layoutElements.length) {
          layoutElements[newIndex].classList.add("active");
        }
      }
    };

    const updateSlider = () => {
      if (sliderContainer.value) {
        const slideWidth = sliderContainer.value.children[0].offsetWidth;
        // Умножаем slideWidth на 20 для перемещения на 20rem
        sliderContainer.value.style.transform = `translateX(-${
          (currentSlide.value * slideWidth * 20) / slideWidth
        }rem)`;
      }
    };

    const slideNumber = computed(() => {
      return `${currentSlideIndex.value} / ${maxSlideOffset.value}`;
    });
    const slideNumberModal = computed(() => {
      return `${currentSlideIndexModal.value} / ${maxSlideOffset.value}`;
    });

    const handleTouchStart = (event) => {
      touchStartX.value = event.touches[0].clientX;
      isDragging.value = true;
    };

    const handleTouchMove = (event) => {
      if (isDragging.value) {
        const currentX = event.touches[0].clientX;
        const deltaX = currentX - touchStartX.value;

        // Ограничение сдвига слайдера
        const maxSlideOffset = layouts.value.length - 1;
        if (currentSlideIndex.value + deltaX / 100 > maxSlideOffset) {
          return;
        } else if (currentSlideIndex.value + deltaX / 100 < 0) {
          return;
        }

        sliderContainer.value.style.transform = `translateX(-${
          currentSlide.value * 100 + deltaX / 100
        }%)`;
      }
    };

    const handleTouchEnd = (event) => {
      if (isDragging.value) {
        const endX = event.changedTouches[0].clientX;
        const deltaX = endX - touchStartX.value;

        // Свайп влево
        if (deltaX > 50) {
          prevSlide();
        }

        // Свайп вправо
        if (deltaX < -50) {
          nextSlide();
        }

        isDragging.value = false;
      }
    };

    onMounted(() => {
      updateSlider();
      updateSliderModal();
      // Вызовите updateTextOnGreen для каждого элемента текста при монтировании
      layouts.value.forEach((layout, index) => {
        updateTextOnGreen(index);
      });
      // Добавьте слушатель для события изменения положения (например, scroll)
      window.addEventListener("scroll", () => {
        layouts.value.forEach((layout, index) => {
          updateTextOnGreen(index);
        });
      });
      // Добавьте слушатель для события изменения размера окна
      window.addEventListener("resize", () => {
        layouts.value.forEach((layout, index) => {
          updateTextOnGreen(index);
        });
      });

      // Добавьте слушатели событий для тача
      sliderContainer.value.addEventListener("touchstart", handleTouchStart);
      sliderContainer.value.addEventListener("touchmove", handleTouchMove);
      sliderContainer.value.addEventListener("touchend", handleTouchEnd);
    });

    return {
      layouts,
      currentSlide,
      sliderContainer,
      sliderContainerModal,
      prevSlide,
      nextSlide,
      prevSlideModal,
      nextSlideModal,
      currentSlideIndex,
      maxSlideOffset,
      slideNumber,
      slideNumberModal,
      isTextOnGreen,
      handleTouchStart,
      handleTouchMove,
      handleTouchEnd,
      touchStartX,
      isDragging,
      toggleAllModals,
      closeModal,
      project_small: "@/assets/img/modal/small_project.svg",
      project_big: "@/assets/img/modal/big_project_img.png",
    };
  },
};
</script>
