<template>
  <section class="section_layouts_mobile">
    <div class="box_text_layouts_mobile">
      <p class="font_48_700 color_green text_align_center">ПЛАНИРОВКИ</p>
      <p class="font_16_400 color_green text_align_right">
        Коттеджи представлены выборочно. Свяжитесь с <br />
        нами, чтобы увидеть все варианты!
      </p>
    </div>
    <div class="container_layouts_mobile">
      <div
        v-for="(layout, index) in visibleLayouts"
        :key="index"
        :id="`box_layouts_mobile_${index}`"
        class="box_layouts_mobile"
      >
        <img class="img_house_mobile" :src="layout.image" />
        <!-- :src="layout.image" -->
        <div class="box_button_layouts_mobile">
          <a
            :href="`#box_layouts_mobile_${index}`"
            class="button_open_modal_mobail"
            @click="() => toggleModal(index)"
          >
            <img
              src="@/assets/img/modal/buttom_open_modal.svg"
              class="img_buttom_open_modal"
            />
          </a>
          <div class="layouts_text_mobil">
            <p class="font_28_700 text_align_right color_white">
              {{ layout.title }}<br />
              {{ layout.subtitle }}
            </p>
          </div>
        </div>
        <div
          ref="container_layouts_modal_mobile"
          class="container_layouts_modal_mobile"
        >
          <div class="title_layouts_modal_mobile">
            <p class="font_28_700 text_align_center color_green">
              {{ layout.title }} {{ layout.subtitle }}
            </p>
            <div
              @click="() => closeModal(index)"
              class="button_cross_modal_v2"
            ></div>
          </div>
          <img class="img_house_mobile_modal" :src="layout.image" />
          <!-- src="@/assets/img/modal/1_house_option_mob.png"
          :src="layout.image" -->
          <div class="block_conten_modal_layouts">
            <div
              @click="() => toggleModalDrawing(index)"
              class="buttom_open_modal_v2"
            >
              <img
                src="@/assets/img/modal/buttom_open_modal.svg"
                class="img_buttom_open_modal"
              />
            </div>
            <img class="small_project_img" :src="layout.project_small" />
            <div class="box_characteristics_text_modal_layouts">
              <div class="box_characteristics_modal_layouts">
                <div class="characteristics_modal_layouts">
                  <img class="bedrooms" src="@/assets/img/modal/bedrooms.svg" />
                  <p class="font_16_500_no color_green">
                    {{ layout.bedrooms }} спальни
                  </p>
                </div>
                <div class="characteristics_modal_layouts">
                  <img
                    class="bedrooms"
                    src="@/assets/img/modal/bathrooms.svg"
                  />
                  <p class="font_16_500_no color_green">
                    {{ layout.bathrooms }} санузла
                  </p>
                </div>
                <div class="characteristics_modal_layouts">
                  <img class="bedrooms" src="@/assets/img/modal/terraces.svg" />
                  <p class="font_16_500_no color_green">
                    {{ layout.terraces }} терраса
                  </p>
                </div>
              </div>
              <p class="font_20_700">{{ layout.characteristics }}</p>
              <p class="font_16_500_no color_green">
                {{ layout.characteristics_2 }}
              </p>
              <p class="font_16_500_no color_green">
                {{ layout.characteristics_3 }}
              </p>
            </div>
          </div>
          <div
            ref="container_full_project_modal_mobil"
            class="container_full_project_modal_mobil"
          >
            <div class="title_layouts_modal_mobile">
              <p class="font_28_700 text_align_center color_green">
                {{ layout.title }} {{ layout.subtitle }}
              </p>
              <div
                class="buttom_open_modal_v3"
                @click="() => closeModalDrawing(index)"
              >
                <img
                  src="@/assets/img/modal/button_left_modal.svg"
                  class="img_buttom_close_modal"
                />
              </div>
            </div>
            <div class="box_big_project_mobil">
              <img class="big_project_img" :src="layout.project_big_1" />
              <img class="big_project_img" :src="layout.project_big_2" />
            </div>
          </div>
        </div>
      </div>
      <div class="box_button_mobile" v-if="hasMoreLayouts">
        <button @click="showMoreLayouts" class="button_full">
          <img src="@/assets/img/elements/down.svg" class="img_buttom_down" />
          <p class="font_16_500_no color_white">ПОКАЗАТЬ ЕЩЁ ПРОЕКТЫ</p>
        </button>
      </div>
    </div>
  </section>
</template>
<script>
import { ref, computed } from "vue";

export default {
  methods: {
    toggleModal(index) {
      this.$refs.container_layouts_modal_mobile[index].classList.toggle(
        "active"
      );
      document.body.classList.add("no-scroll");
    },
    closeModal(index) {
      this.$refs.container_layouts_modal_mobile[index].classList.toggle(
        "active"
      );
      document.body.classList.remove("no-scroll");
    },
    toggleModalDrawing(index) {
      this.$refs.container_full_project_modal_mobil[index].classList.toggle(
        "active"
      );
    },
    closeModalDrawing(index) {
      this.$refs.container_full_project_modal_mobil[index].classList.toggle(
        "active"
      );
    },
  },
  computed: {
    visibleLayouts() {
      return this.layouts.slice(0, this.visibleLayoutsCount);
    },
    hasMoreLayouts() {
      return this.visibleLayoutsCount < this.layouts.length;
    },
  },
  setup() {
    const layouts = ref([
      {
        image: "/img/1_house_option_mob.d0194244.png",
        title: "Коттедж",
        subtitle: "“Название1”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/2_house_option_mob.8535687a.png",
        title: "Коттедж",
        subtitle: "“Название2”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/3_house_option_mob.63074ca3.png",
        title: "Коттедж",
        subtitle: "“Название3”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/1_house_option_mob.d0194244.png",
        title: "Коттедж",
        subtitle: "“Название1”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/2_house_option_mob.8535687a.png",
        title: "Коттедж",
        subtitle: "“Название2”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/3_house_option_mob.63074ca3.png",
        title: "Коттедж",
        subtitle: "“Название3”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/1_house_option_mob.d0194244.png",
        title: "Коттедж",
        subtitle: "“Название1”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/2_house_option_mob.8535687a.png",
        title: "Коттедж",
        subtitle: "“Название2”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
      {
        image: "/img/3_house_option_mob.63074ca3.png",
        title: "Коттедж",
        subtitle: "“Название3”",
        bedrooms: "3",
        bathrooms: "2",
        terraces: "1",
        characteristics: "Одноэтажный дом площадью 200 м2",
        characteristics_2:
          "Просторный дом со скатной крышей для семьи с детьми.",
        characteristics_3:
          "Комфорт и уют: мастер-спальня, две детские, кухня-гостиная с высокими потолками, гардероб, тепловой узел, крытые террасы",
        project_small: "/img/small_project.0a47e0a1.svg",
        project_big_1: "/img/big_project_img.c6302218.png",
        project_big_2: "/img/big_project_img.c6302218.png",
      },
    ]);
    const visibleLayoutsCount = ref(3);

    const visibleLayouts = computed(() => {
      return layouts.value.slice(0, visibleLayoutsCount.value);
    });

    const hasMoreLayouts = computed(() => {
      return visibleLayoutsCount.value < layouts.value.length;
    });

    const showMoreLayouts = () => {
      visibleLayoutsCount.value = Math.min(
        visibleLayoutsCount.value + 3,
        layouts.value.length
      );
    };

    return {
      layouts,
      setupVisibleLayouts: visibleLayouts, // Новое имя!
      setupHasMoreLayouts: hasMoreLayouts, // Новое имя!
      visibleLayoutsCount,
      showMoreLayouts,
    };
  },
};
</script>
